import axios from '@/config/axios';
import utils from "@/utils/utils";

export const systemAdminService = {

    getForeclosureStatsAsSpreadsheet() {
        axios.get(`admin/foreclosure-stats`, {responseType: 'arraybuffer', timeout: 0}).then((res) => {
            let FILE = window.URL.createObjectURL(new Blob([res.data]));
            let docUrl = document.createElement('a');
            docUrl.href = FILE;
            docUrl.setAttribute('download', 'tvangsauktioner.xlsx');
            document.body.appendChild(docUrl);
            docUrl.click();
        });

    },

    listCompanies() {
        return axios.get(`admin/companies`);
    },

    getCompany(companyId) {
        return axios.get(`admin/company/${companyId}`);
    },

    addCompany(company) {
        return axios.post(`admin/company`, company);
    },

    updateCompany(company) {
        return axios.put(`admin/company`, company);
    },

    uploadPhoto(file, companyId, logoType) {
        return axios.post(`admin/company/${companyId}/${logoType}`, utils.toFormData([{name: 'file', type: 'file', data: file}]), {timeout: 30000});
    },

    download(companyId, logoType) {
        return axios.get(`admin/company/${companyId}/logo/${logoType}/download`, {responseType: 'arraybuffer', timeout: 0});
    },

    listUsers(companyId) {
        return axios.get(`admin/users/${companyId}`);
    },

    getUser(userId) {
        return axios.get(`admin/user/${userId}`);
    },

    addUser(user) {
        return axios.post(`admin/user`, user);
    },

    updateUser(user) {
        return axios.put(`admin/user`, user);
    },

    changeUser(userId) {
        return axios.post(`admin/user/change/${userId}`);
    },

    changeBack() {
        return axios.put(`admin/user/change-back`);
    },

    listLawyerCaseManager(companyId) {
        return axios.get(`debt-collection/lawyer-casemanager/admin/${companyId}/list`);
    },

    addSupportLog(systemLog) {
        return axios.post(`admin/system/add-support-log`, systemLog);
    }
}
