<template>
  <div class="container">
    <header class="jumbotron">
      <h3>Du er på rette vej :) vi skal bare lige viderestille dig</h3>
      <b-link @click="continueTo">Hvis der ikke rigtig sker noget, så klik her for at komme videre</b-link>
    </header>
  </div>
</template>

<script>

export default {
  name: 'HomePage',
  data() {
    return {
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    continueTo() {
      if (!this.currentUser) {
        this.$router.push('/login');
      } else if (this.currentUser.roles.includes('ADMIN')) {
        this.$router.push('/admin');
      } else if (this.currentUser.roles.includes('LAWYER')) {
        this.$router.push('/lawyer');
      } else if (this.currentUser.roles.includes('MORTGAGE')) {
        this.$router.push('/mortgage');
      } else if (this.currentUser.roles.includes('SYSTEM')) {
        this.$router.push('/openapi');
      }
    }

  },
  mounted() {
    this.continueTo();
  }
};
</script>