import axios from '@/config/axios';

export const systemNewsService = {

    get() {
        return axios.get(`system-news`);
    },
    set(systemNews) {
        return axios.post(`system-news`, systemNews);
    },
    delete(systemNews) {
        return axios.delete(`system-news`, systemNews);
    },
    list() {
        return axios.get(`system-news/list`);
    },
    listActive() {
        return axios.get(`system-news/list/active`);
    },
    setUserRead(systemNewsId) {
        return axios.get(`system-news/read/${systemNewsId}`);
    }
}
